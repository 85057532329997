<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewShiftSidebarActive"
    backdrop
    bg-variant="white"
    no-header
    right
    shadow
    sidebar-class="sidebar-lg"
    @change="(val) => $emit('update:is-add-new-shift-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" rounded="sm">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Vacant Shift -
            {{ momentFormat(selectedDate, "dddd, DD MMMM YYYY") }}
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <div class="p-2">
          <validation-observer ref="createShiftForm">
            <b-form ref="add_shift" @submit.prevent>
              <b-alert v-if="isHaveLeave" show variant="danger">
                <div class="alert-body">
                  <feather-icon class="mr-25" icon="AlertCircleIcon" />
                  <span class="ml-25">{{ leaveErrorMessage }}</span>
                </div>
              </b-alert>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <label>Professions</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Professions"
                      rules="required"
                    >
                      <v-select
                        v-model="selectedRole"
                        :options="roleList"
                        label="text"
                        placeholder="Select Profession"
                        multiple
                        :reduce="(roleList) => roleList.id"
                      />
                      <small v-if="errors[0]" class="text-danger"
                        >The Role field is required</small
                      >
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <label>Role (Optional)</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Role"
                      rules=""
                    >
                      <v-select
                        v-model="selectedRoles"
                        :options="RoleLists"
                        :reduce="(RoleLists) => RoleLists.value"
                        label="text"
                        placeholder="Select Role"
                        multiple
                      >
                        <span slot="no-options">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            size="sm"
                            variant="primary"
                            @click="$router.push(`/settings?active_tab=1`)"
                            >Add</b-button
                          >
                        </span>
                      </v-select>
                      <small v-if="errors[0]" class="text-danger"
                        >The Professions field is required</small
                      >
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <h5 class="mt-2" v-if="ShiftTemplates.length !== 0">
                Shift templates (select applicable shift)
              </h5>

              <div class="container-fluid">
                <div class="scrolling-wrapper row flex-row flex-nowrap pt-2">
                  <div
                    v-for="template in ShiftTemplates"
                    :index="template.id"
                    :key="template.id"
                    class="col-5"
                    @click="shiftTemplateSet(template)"
                  >
                    <div
                      class="card card-block"
                      v-bind:class="
                        template.isSelect === true
                          ? cardBckground2
                          : cardBckground1
                      "
                      @click="changeColor(template)"
                    >
                      <b-row>
                        <b-col cols="6">
                          <span
                            style="
                              padding-left: 7px;
                              color: white;
                              font-size: x-small;
                            "
                          >
                            {{ template.start_time }} - {{ template.end_time }}
                          </span>
                        </b-col>
                        <b-col cols="6">
                          <div
                            class=""
                            style="
                              background-color: white;
                              text-overflow: ellipsis;
                              margin-right: 7px;
                            "
                          >
                            <span
                              style="
                                padding: 3px;
                                color: #353434;
                                font-size: x-small;
                              "
                              >{{
                                template.service.name.substring(0, 12)
                              }}</span
                            >
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <b-row>
                  <!--                  <b-col cols="12" md="4">-->
                  <!--                    <label>Date</label>-->

                  <!--                    <validation-provider-->
                  <!--                      #default="{ errors }"-->
                  <!--                      name="Date"-->
                  <!--                      rules="required"-->
                  <!--                    >-->
                  <!--                      <b-form-group>-->
                  <!--                        <b-form-datepicker-->
                  <!--                          id="date"-->
                  <!--                          v-model="selectedDate"-->
                  <!--                          :date-format-options="{-->
                  <!--                            month: 'numeric',-->
                  <!--                            day: 'numeric',-->
                  <!--                            year: 'numeric',-->
                  <!--                          }"-->
                  <!--                          :min="min"-->
                  <!--                          locale="en-UK"-->
                  <!--                          class="form-control"-->
                  <!--                          placeholder="Selects"-->
                  <!--                          start-weekday="1"-->
                  <!--                          trim-->
                  <!--                        />-->
                  <!--                        &lt;!&ndash; <flat-pickr-->
                  <!--                            v-model="selectedDate"-->
                  <!--                            :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d', locale: {-->
                  <!--                          firstDayOfWeek: 1-->
                  <!--                      }}"-->
                  <!--                            class="form-control"-->
                  <!--                            placeholder="Select a date"-->
                  <!--                        /> &ndash;&gt;-->
                  <!--                      </b-form-group>-->
                  <!--                      <small class="text-danger">{{ errors[0] }}</small>-->
                  <!--                    </validation-provider>-->
                  <!--                  </b-col>-->
                  <b-col cols="12" md="4">
                    <b-form-group>
                      <label>Start Time</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Start Time"
                        rules="required"
                      >
                        <b-form-group>
                          <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                              <feather-icon icon="ClockIcon" />
                            </b-input-group-prepend>
                            <flat-pickr
                              v-model="selectedStartTime"
                              :config="{
                                enableTime: true,
                                dateFormat: 'H:i:ss',
                                noCalendar: true,
                              }"
                              class="form-control"
                              placeholder="Start Time"
                            />
                          </b-input-group>
                        </b-form-group>
                        <small v-if="errors[0]" class="text-danger"
                          >The Start Time field is required</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group>
                    <label>End Time</label>

                    
                      <validation-provider
                        #default="{ errors }"
                        name="End Time"
                        rules="required"
                      >
                      <b-form-group>
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                              <feather-icon icon="ClockIcon" />
                            </b-input-group-prepend>
                        <flat-pickr
                          v-model="selectedEndTime"
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i:ss',
                          }"
                          class="form-control"
                          placeholder="End Time"
                        />
                        </b-input-group>
                        </b-form-group>
                        <small v-if="errors[0]" class="text-danger"
                          >The End Time field is required</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Break Minutes" label-for="login-email">
                      <validation-provider
                        #default="{ errors }"
                        name="Break Minutes (Optional)"
                        rules="numeric"
                      >
                        <b-form-input
                          id="login-email"
                          v-model="form.break_minutes"
                          name="login-email"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label="Shift delivery (Optional)"
                      label-for="lShift_delivery"
                    >
                      <v-select
                        v-if="selectedServiceType"
                        v-model="selectedShiftType"
                        :options="shiftTypes"
                        label="name"
                        placeholder="Select delivery"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <div class="mt-1">
                <h6 v-if="visible_locums == false" class="mb-2">
                  Hourly rate (Optional)
                </h6>
                <b-row v-if="visible_locums == false" class="mb-1">
                  <b-col cols="3">
                    <b-form-checkbox
                      v-model="form.salary_staff_rate_type"
                      value="standard"
                    >
                      Standard
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="3">
                    <b-form-checkbox
                      v-model="form.salary_staff_rate_type"
                      value="enhanced"
                    >
                      Enhanced
                    </b-form-checkbox>
                  </b-col>
                </b-row>

                <validation-provider
                  v-if="
                    visible_locums == false &&
                    (form.salary_staff_rate_type === 'standard' ||
                      form.salary_staff_rate_type === 'enhanced')
                  "
                  #default="{ errors }"
                  class=""
                  name="Salaried hourly rate"
                  rules="required|numeric"
                >
                  <b-input-group prepend="£" size="md">
                    <b-form-input
                      id="stand"
                      v-model="form.salary_staff_rate"
                      class="float-right"
                      placeholder="Enter hourly rate"
                      type="number"
                    />
                  </b-input-group>
                  <small
                    v-if="
                      form.salary_staff_rate_type === 'standard' &&
                      !standard_rate_p_h
                    "
                    >Please add standard rate in setting or enter
                    manually</small
                  >
                  <small
                    v-if="
                      form.salary_staff_rate_type === 'enhanced' &&
                      !enhanced_rate_p_h
                    "
                    >Please add enhanced rate in setting or enter
                    manually</small
                  >
                  <br />
                  <!--                  <b-input-group-->
                  <!--                      size="sm"-->
                  <!--                      append=".00"-->
                  <!--                      prepend="$"-->
                  <!--                  >-->
                  <!--                  <b-form-input-->
                  <!--                      id="stand"-->
                  <!--                      v-model="form.salary_staff_rate"-->
                  <!--                      class="float-right mb-1   "-->
                  <!--                      name="login-email"-->
                  <!--                      placeholder="Enter hourly rate"-->

                  <!--                      type="number"-->
                  <!--                  />-->
                  <!--                  </b-input-group>-->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <div v-if="visible_locums === true">
                  <h6 class="mb-1">Hourly rate (Optional)</h6>

                  <validation-provider
                    #default="{ errors }"
                    name="Locum hourly rate"
                    rules="required"
                  >
                    <b-input-group prepend="£" size="md">
                      <b-form-input
                        id="rate"
                        v-model="form.locum_rate"
                        class="float-right"
                        name="login-email"
                        placeholder="Enter hourly rate"
                        type="number"
                      />
                    </b-input-group>
                    <small v-if="!locum_rate_p_h"
                      >Please add locum rate in setting or enter manually</small
                    >
                    <br />
                    <!--                    <b-form-input-->
                    <!--                        id="rate"-->
                    <!--                        v-model="form.locum_rate"-->
                    <!--                        class="float-right "-->
                    <!--                        name="login-email"-->
                    <!--                        placeholder="Enter hourly rate"-->

                    <!--                        type="number"-->
                    <!--                    />-->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </div>

              <!--              <b-form-checkbox-->
              <!--                  v-model="isVacant"-->
              <!--                  value="true"-->

              <!--              >-->
              <!--                Vacant Shift-->
              <!--              </b-form-checkbox>-->

              <!--           <div class="mt-1">-->
              <!--               <b-form-group>-->
              <!--                   <label >Shift Type</label>-->
              <!--                   <v-select-->
              <!--                           v-model="selectedService"-->
              <!--                           label="name"-->
              <!--                           placeholder="Select"-->
              <!--                           :options="services"-->
              <!--                   />-->
              <!--               </b-form-group>-->
              <!--           </div>-->
              <!--           <b-form-checkbox-->
              <!--                   v-model="selected"-->
              <!--                   value="A"-->
              <!--           >-->
              <!--               COVID-19 Shift-->
              <!--           </b-form-checkbox>-->

              <div class="mt-2">
                <h5
                  class="mb-2 cursor-pointer"
                  @click="hideNoteAndShiftTypeFunction()"
                >
                  <span v-if="!hideNoteAndShiftType">+</span
                  ><span v-if="hideNoteAndShiftType">-</span> More Shift Options
                </h5>
                <div class="mt-1" v-if="hideNoteAndShiftType">
                  <div class="mt-1" v-if="services.length > 0">
                    <b-row class="mt-2">
                      <b-col md="12">
                        <h5
                          class="mb-2 cursor-pointer"
                          @click="
                            serviceTypeHideFunction();
                            selectedServiceType = 'clinical';
                          "
                        >
                          Activity (Optional)
                        </h5>
                      </b-col>

                      <b-col md="6">
                        <b-form-checkbox
                          v-model="selectedServiceType"
                          class="float-left"
                          value="clinical"
                        >
                          Clinical
                        </b-form-checkbox>
                        <b-form-checkbox
                          v-model="selectedServiceType"
                          class="float-right"
                          value="non-clinical"
                        >
                          Non-clinical
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                    <b-row class="">
                      <b-col md="12">
                        <b-form-group>
                          <label></label>
                          <!--                      rules="required"-->
                          <validation-provider
                            #default="{ errors }"
                            :rules="selectedServiceType ? 'required' : ''"
                            name="Service"
                          >
                            <v-select
                              v-if="selectedServiceType === 'clinical'"
                              v-model="selectedService"
                              :options="
                                services.filter((x) => x.type === 'clinical')
                              "
                              label="name"
                              placeholder="Select Service"
                            >
                              <span slot="no-options">
                                Please add services first
                              </span>
                            </v-select>
                            <v-select
                              v-if="selectedServiceType === 'non-clinical'"
                              v-model="selectedService"
                              :options="
                                services.filter(
                                  (x) => x.type === 'non-clinical'
                                )
                              "
                              label="name"
                              placeholder="Select Service"
                            >
                              <span slot="no-options">
                                Please add services first
                              </span>
                            </v-select>
                            <small
                              v-if="selectedServiceType"
                              class="text-danger"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                  <b-row
                    v-if="
                      selectedServiceType === 'clinical' &&
                      services.length !== 0
                    "
                    class=""
                  >
                  </b-row>

                  <div class="mt-1">
                    <label>Notes (Optional)</label>
                    <b-form-textarea
                      id="textarea-default"
                      v-model="form.note"
                      placeholder="Note"
                      rows="3"
                    />
                  </div>
                  <b-form-checkbox
                    v-model="form.is_covid_shift"
                    :value="true"
                    class="mt-1"
                  >
                    COVID-19 Shift
                  </b-form-checkbox>
                  <div class="mt-1">
                    <h5 class="mb-2 text-bold">Shift is visible to</h5>
                    <b-row>
                      <b-col cols="12" md="4">
                        <b-form-checkbox
                          v-model="visible_locums"
                          :value="false"
                        >
                          Salaried Staff
                        </b-form-checkbox>
                      </b-col>
                      <b-col cols="12" md="8">
                        <b-form-checkbox v-model="visible_locums" :value="true">
                          Locums
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <b-alert show variant="primary">
                  <div class="alert-body">
                    <b-row>
                      <b-col class="text-bold text-black-50" cols="4">
                        <h5>
                          Total Hours:
                          <span v-if="selectedEndTime && selectedStartTime">
                            {{ totalHours }}

                            <!--                            {{ selectedEndTime.substring(0, 2) - selectedStartTime.substring(0, 2) }}-->
                          </span>
                          <span v-else>0</span>
                        </h5>
                      </b-col>
                      <b-col class="text-bold text-black-50" cols="8"
                        ><h5>
                          Total Shift Cost:

                          <span>
                            <span
                              >£
                              {{
                                salariedStaffCost <= 0 ? 0 : salariedStaffCost
                              }}</span
                            ><span v-if="visible_locums === true"
                              >/ £{{ locumCost <= 0 ? 0 : locumCost }}</span
                            >

                            <!--                          {{totalShiftCost(selectedStartTime,selectedEndTime, 10)}}-->
                          </span>
                        </h5></b-col
                      >
                    </b-row>
                  </div>
                </b-alert>
              </div>
              <div class="flex mt-1">
                <b-button
                  v-if="isHaveLeave"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  disabled
                  type="submit"
                  variant="primary"
                  @click="createShift()"
                >
                  Save
                </b-button>
                <b-button
                  v-else
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  @click="createShift()"
                >
                  Save
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="ml-1"
                  style="
                    background: #ffffff;
                    box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.2);
                    border-radius: 4px;
                  "
                  variant="white"
                  @click="hide"
                >
                  Cancel
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>

        <!--        {{projectResourceDataSource}}-->
        <!--        {{selectedRole}}-->
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BListGroup,
  BListGroupItem,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  VBModal,
  VBToggle,
  VBTooltip,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import shift from "@/apis/modules/shift";
import MomentMixin from "@/mixins/MomentMixin";
import moment from "moment";
import SettingsAPI from "@/apis/modules/settings";
import settings from "@/apis/modules/settings";

export default {
  name: "sideBar",
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BFormGroup,
    BButton,
    BSidebar,
    BFormCheckbox,
    BFormTextarea,
    flatPickr,
    BOverlay,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BAlert,
    BFormSelect,
    BPagination,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BFormInvalidFeedback,
    BInputGroupPrepend,
    BInputGroupAppend,

    VBTooltip,
  },
  mixins: [MomentMixin],
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-modal": VBModal,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth());
    minDate.setDate(minDate.getDate());

    return {
      selectedRoles: [],
      RoleLists: [],
      hideNoteAndShiftType: false,
      cardBckground1: "card-1",
      cardBckground2: "card-2",
      is_cell_clicked: false,
      totalHours: 0,
      salariedStaffCost: 0,
      locumCost: 0,
      rateCost: 0,
      roleHourlyRates: {},
      isHaveLeave: false,
      leaveErrorMessage: "",
      loading: false,
      dasibleProfession: false,
      isVacant: true,
      serviceTypeHide: false,
      breakTimeHide: false,
      selectedHealthcare_professional: undefined,
      selectedRole: undefined,
      roleList: [],
      professionsList: [],
      selectedServiceType: "clinical",
      selectedDate: "",
      selectedStartTime: "",
      selectedEndTime: "",
      data: "",
      min: minDate,
      selected: "",
      timePicker: "",
      services: [],
      selectedService: "",
      selectedShiftType: "",
      shiftTypes: [],
      visible_locums: false,
      form: {
        is_visible_locums: "",
        start: "",
        end: "",
        note: "",
        break_minutes: "",
        service_id: "",
        assignee_id: "",
        is_covid_shift: false,
        shift_type_id: "",
        salary_staff_rate_type: "standard",
        salary_staff_rate: 0,
        locum_rate: 0,
        role_id: undefined,
      },
      employeeDataSource: [],
      projectResourceDataSource: [],
      enhanced_rate_p_h: "",
      standard_rate_p_h: "",
      locum_rate_p_h: "",
      ShiftTemplates: [],
      clinicalAndNonClinicalServices: [],
    };
  },
  model: {
    prop: "isAddNewShiftSidebarActive",
    event: "update:is-add-new-shift-sidebar-active",
  },
  props: {
    isAddNewShiftSidebarActive: {
      type: Boolean,
      required: true,
    },
    cellData: {
      type: Object,
      required: true,
    },
    leaveData: {
      type: Array,
      required: true,
    },
  },
  watch: {
    async isAddNewShiftSidebarActive(val) {
      if (!val) {
        await this.dataClear();
        this.selectedRole = undefined;
        this.selectedHealthcare_professional = undefined;
        this.selectedDate = await this.momentFormat(new Date(), "YYYY-MM-DD");
        /* this.$nextTick(() => {
           this.$refs.add_shift.reset()
         })*/
        return;
      } else {
        await this.getJobRoleList();
        await this.getRole();
        await this.getUserData();
        await this.getShiftTemplates();
        await this.getServices();
        await this.getShiftTypes();
      }

      /* if (!this.is_cell_clicked){
          this.selectedRole = undefined
          this.selectedHealthcare_professional = undefined
         this.$nextTick(() => {
           this.$refs.add_shift.reset()
         })
       }*/
      this.isHaveLeave = false;
      this.leaveErrorMessage = "";
      this.selectedStartTime = "08:00:00";
    },
    "form.salary_staff_rate_type"(val) {
      if (val == "enhanced") {
        this.form.salary_staff_rate = this.enhanced_rate_p_h
          ? this.enhanced_rate_p_h
          : 0;
      } else {
        this.form.salary_staff_rate = this.standard_rate_p_h
          ? this.standard_rate_p_h
          : 0;
      }
    },
    async cellData() {
      this.data = await this.cellData;
      if (this.data) {
        this.selectedDate = await this.momentFormat(
          this.data.data.StartTime,
          "YYYY-MM-DD"
        );
      }
    },
    selectedServiceType() {
      // this.getServices()
      // this.getShiftTypes()
    },
    isVacant() {
      if (this.isVacant) {
        this.dasibleProfession = true;
        this.selectedHealthcare_professional = undefined;
      } else {
        this.dasibleProfession = false;
      }
    },
    selectedDate() {
      this.isUserHaveLeave();
    },
    selectedStartTime() {
      this.isUserHaveLeave();
      this.totalHours = this.timeDifferentHours(
        this.selectedStartTime,
        this.selectedEndTime
      );
      this.salariedStaffCost = this.totalShiftCost(
        this.totalHours,
        this.form.salary_staff_rate
      );
    },
    selectedEndTime() {
      this.isUserHaveLeave();
      this.totalHours = this.timeDifferentHours(
        this.selectedStartTime,
        this.selectedEndTime
      );
      this.salariedStaffCost = this.totalShiftCost(
        this.totalHours,
        this.form.salary_staff_rate
      );
    },
    "form.break_minutes"() {
      this.isUserHaveLeave();
      this.totalHours = this.timeDifferentHours(
        this.selectedStartTime,
        this.selectedEndTime,
        this.form.break_minutes
      );
      this.salariedStaffCost = this.totalShiftCost(
        this.totalHours,
        this.form.salary_staff_rate
      );
    },
    selectedHealthcare_professional() {
      // this.isVacant = false
      this.isUserHaveLeave();
    },
    selectedRole(val) {
      this.form.salary_staff_rate = 0;
      this.form.locum_rate = 0;
      // this.isUserHaveLeave()
      if (val.length === 1) {
        this.getJobRoleList(val[0]);
      } else {
        this.form.salary_staff_rate = 0;
        this.form.locum_rate = 0;
      }
      this.filterJobRoles(val);

      // this.getJobRoleList(val[0])
    },
    "form.salary_staff_rate"(newValue) {
      this.totalHours = this.timeDifferentHours(
        this.selectedStartTime,
        this.selectedEndTime,
        this.form.break_minutes
      );
      this.salariedStaffCost = this.totalShiftCost(this.totalHours, newValue);
    },
    "form.locum_rate"(newValue) {
      this.totalHours = this.timeDifferentHours(
        this.selectedStartTime,
        this.selectedEndTime,
        this.form.break_minutes
      );
      this.locumCost = this.totalShiftCost(this.totalHours, newValue);
    },
  },
  methods: {
    async filterJobRoles(val, id = null) {
      try {
        this.loading = true;
        let allRoles = [];
        const Response = await settings.getRolesWithoutPagination();
        allRoles = Response.data.data.map((x) => ({
          text: x.name,
          value: x.id,
          profession: x.professions,
        }));

        let selectedRoleIDS = [];
        allRoles.map((x) => {
          x.profession.map((y) => {
            val.map((k) => {
              if (k === y) selectedRoleIDS.push(x.value);
            });
          });
        });

        this.RoleLists = selectedRoleIDS.map((x) => {
          return allRoles.find((e) => e.value === x);
        });

        this.RoleLists = Array.from(
          new Set(this.RoleLists.map((a) => a.value))
        ).map((value) => {
          return this.RoleLists.find((a) => a.value === value);
        });
        this.loading = false;
      } catch (e) {}
    },
    hideNoteAndShiftTypeFunction() {
      this.hideNoteAndShiftType = !this.hideNoteAndShiftType;
    },
    changeColor(data) {
      Object.keys(this.ShiftTemplates).forEach((key) => {
        this.ShiftTemplates[key].isSelect = false;
      });
      data.isSelect = true;
    },
    async cellClicked() {
      this.is_cell_clicked = true;
      setTimeout(() => {
        this.is_cell_clicked = false;
      }, 1000);
    },
    async isUserHaveLeave() {
      const leaves = this.leaveData.filter(
        (leave) =>
          this.momentFormat(leave.StartTime, "YYYY-MM-DD") ===
            this.selectedDate &&
          leave.group_id === this.selectedRole.id &&
          leave.user_id === this.selectedHealthcare_professional.id
      );
      if (leaves.length !== 0) {
        if (leaves.filter((leave) => leave.is_day === 1).length !== 0) {
          // this.showErrorMessage(`${this.selectedHealthcare_professional.text} have full day leave on ${this.selectedDate}` )
          this.isHaveLeave = true;
          this.leaveErrorMessage = `${this.selectedHealthcare_professional.text} have full day leave on ${this.selectedDate}`;
        }
        if (leaves.filter((leave) => leave.is_day === 0).length !== 0) {
          const leaveStartTime = moment(leaves[0].StartTime, "hh:mm:ss");
          const leaveEndTime = moment(leaves[0].EndTime, "hh:mm:ss");
          const beforeTime = moment(
            new Date(this.selectedDate + ` ` + this.selectedStartTime),
            "hh:mm:ss"
          );
          const afterTime = moment(
            new Date(this.selectedDate + ` ` + this.selectedEndTime),
            "hh:mm:ss"
          );
          const leaveType = leaves[0].state;

          if (
            leaveStartTime.isBetween(beforeTime, afterTime) ||
            leaveEndTime.isBetween(beforeTime, afterTime) ||
            beforeTime.isBetween(leaveStartTime, leaveEndTime) ||
            afterTime.isBetween(leaveStartTime, leaveEndTime)
          ) {
            // this.showErrorMessage(`${this.selectedHealthcare_professional.text}
            //  have  leave on ${this.momentFormat(leaveStartTime,'HH:mm')} - ${this.momentFormat(leaveEndTime, 'HH:mm')}` )

            this.isHaveLeave = true;
            if (leaveType === "leave") {
              this.leaveErrorMessage = `${
                this.selectedHealthcare_professional.text
              }
             have  leave on ${this.momentFormat(
               leaveStartTime,
               "HH:mm"
             )} - ${this.momentFormat(leaveEndTime, "HH:mm")}`;
            } else {
              this.leaveErrorMessage = `${
                this.selectedHealthcare_professional.text
              }
             have  Un Available on ${this.momentFormat(
               leaveStartTime,
               "HH:mm"
             )} - ${this.momentFormat(leaveEndTime, "HH:mm")}`;
            }
          } else {
            this.isHaveLeave = false;
          }
        }
      } else {
        this.isHaveLeave = false;
      }
    },
    getCalculatedCost() {
      return (
        (this.selectedEndTime.substring(0, 2) -
          this.selectedStartTime.substring(0, 2)) *
        this.form.rate
      );
    },
    serviceTypeHideFunction() {
      this.serviceTypeHide = !this.serviceTypeHide;
    },
    async getServices() {
      try {
        this.loading = true;
        const serviceResponse = await shift.getServicesInPractice();

        this.selectedService = "";
        this.services = serviceResponse.data.data.map((x) => ({
          name: x.name,
          id: x.id,
          type: x.type,
        }));
        this.clinicalAndNonClinicalServices =
          this.clinicalAndNonClinicalServices.concat(this.services);
        this.loading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
    async getShiftTypes() {
      try {
        this.loading = true;
        const serviceResponse = await shift.getShiftTypesInPractice();

        this.shiftTypes = serviceResponse.data.data.map((x) => ({
          name: x.name,
          id: x.id,
        }));
        this.loading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
    async getUserData() {
      try {
        this.loading = true;

        const response = await shift.userList();
        // const jobRoles = response.data.data[0].job_roles[0]
        const users = response.data.data.filter(
          (user) => user.job_roles.length !== 0
        );

        let groupArray = users.reduce(function (filtered, option) {
          option.job_roles.forEach((m) => {
            var obj = {
              text: m.name,
              id: m.id,
              color: "#00C49A",
              isGroup: true,
            };
            filtered.push(obj);
          });
          filtered = [
            ...new Map(filtered.map((item) => [item.id, item])).values(),
          ];

          return filtered;
        }, []);

        let userArray = users.map((x) => ({
          text: `${x.first_name} ${x.last_name}`,
          id: x.id,
          groupId: x.job_roles[0].id,
          image: "1",
        }));
        if (this.data.data) {
          this.selectedRole = Object(
            groupArray.filter((m) => m.id === this.data.data.group_id)
          )[0];
          this.selectedHealthcare_professional = Object(
            userArray.filter((m) => m.id === this.data.data.user_id)
          )[0];
        }
        // this.projectResourceDataSource = groupArray
        this.employeeDataSource = userArray;
        this.loading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
    async getJobRoleList(id) {
      try {
        this.loading = true;

        const Response = await SettingsAPI.getJobRoleList();
        const ResponseWithRate = await SettingsAPI.jobRoleAssignedList();

        const job_role_with_rate = ResponseWithRate.data.data.map((x) => ({
          id: x.id,
          job_role_id: x.pivot.job_role_id,
          text: x.name,
          standard_rate_p_h: x.pivot.standard_rate_p_h,
          enhanced_rate_p_h: x.pivot.enhanced_rate_p_h,
          locum_rate_p_h: x.pivot.locum_rate_p_h,
        }));

        const job_role_without_rate = Response.data.data.map((x) => ({
          id: x.id,
          text: x.name,
          job_role_id: x.id,
          standard_rate_p_h: "",
          enhanced_rate_p_h: "",
          locum_rate_p_h: "",
        }));

        const allJobRoleArray = job_role_with_rate.concat(
          job_role_without_rate
        );

        this.roleList = _.uniqBy(allJobRoleArray, "id");

        const role = [];
        this.roleList.forEach(function (x) {
          if (x.id == id) {
            role.push(x);
          }
        });
        if (role.length == 0) {
          this.form.salary_staff_rate = 0;
          this.form.locum_rate = 0;
          this.enhanced_rate_p_h = "";
          this.standard_rate_p_h = "";
          this.locum_rate_p_h = "";
        }

        this.loading = false;
        this.locum_rate_p_h = role[0].locum_rate_p_h;
        this.standard_rate_p_h = role[0].standard_rate_p_h;
        this.enhanced_rate_p_h = role[0].enhanced_rate_p_h;
        this.form.locum_rate = role[0].locum_rate_p_h
          ? role[0].locum_rate_p_h
          : 0;
        this.form.salary_staff_rate = role[0].standard_rate_p_h
          ? role[0].standard_rate_p_h
          : 0;

        this.loading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
    async dataClear() {
      /* this.$nextTick(() => {
         this.$refs.add_shift.reset()
       })*/
      this.selectedRoles = [];
      this.data = null;
      this.isVacant = false;
      this.selectedRole = undefined;
      this.selectedHealthcare_professional = null;
      this.selectedService = [];
      this.form.rate = "";
      this.selectedServiceType = "clinical";
      this.selectedShiftType = "";
      this.serviceTypeHide = false;
      this.totalHours = 0;
      this.selectedStartTime = undefined;
      this.selectedEndTime = undefined;
      this.selectedDate = "";
      this.form.break_minutes = "";
      this.form.note = "";
      this.form.salary_staff_rate = "";
      this.form.salary_staff_rate_type = "standard";
      this.form.locum_rate = "";
      (this.enhanced_rate_p_h = ""),
        (this.standard_rate_p_h = ""),
        (this.locum_rate_p_h = ""),
        this.$refs.createShiftForm.reset();
    },

    async createShift() {
      if (await this.$refs.createShiftForm.validate()) {
        try {
          // let roleIds = this.selectedRoles.map((x)=>{
          //   return x.value
          // })
          this.loading = true;
          this.form.is_visible_locums = this.visible_locums;
          this.form.job_role_id = "";
          this.form.role_id = this.selectedRoles;
          this.form.job_role_ids = this.selectedRole;
          this.form.service_id = this.selectedService.id;
          this.form.shift_type_id = this.selectedShiftType.id;
          this.form.start = moment(
            this.selectedDate + ` ` + this.selectedStartTime
          )
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
          this.form.end = moment(this.selectedDate + ` ` + this.selectedEndTime)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
          this.form.assignee_id = "";
          const response = await shift.createVacantShift(this.form);

          this.loading = false;
          if (response.data.data.error) {
            this.showErrorMessage(
              "There is an existing shift in this time slot"
            );
          } else {
            this.showSuccessMessage("Shift Added Successfully");
            this.$emit("createShift");
          }
        } catch (error) {
          this.convertAndNotifyError(error);
          this.loading = false;
        }
      }
    },

    async shiftTemplateSet(template) {
      this.form.break_minutes = template.break_minutes;
      this.selectedStartTime = template.start_time;
      this.selectedEndTime = template.end_time;
      this.serviceTypeHide = true;
      this.selectedServiceType = template.service.type;
      this.selectedService = template.service;
      this.form.note = template.note;
      // if (template.service.type ==='clinical'){
      //
      // }else {
      //
      // }
      // this.selectedService = template.service

      try {
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },

    async getShiftTemplates() {
      try {
        const response = await shift.getShiftTemplateWithoutPagination();
        this.ShiftTemplates = response.data.data.map((x) => ({
          id: x.id,
          start_time: x.start_time,
          end_time: x.end_time,
          break_minutes: x.break_minutes,
          note: x.note,
          service: {
            name: x.service.name,
            id: x.service.id,
            type: x.service.type,
          },
          isSelect: false,
        }));
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },

    async getRole() {
      try {
        const Response = await settings.getRolesWithoutPagination();
        this.professionsList = Response.data.data.map((x) => ({
          text: x.name,
          value: x.id,
          profession: x.professions,
        }));
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss">
.scrolling-wrapper {
  overflow-x: auto;
}

.card-block {
  height: 40px;
  padding-top: 10px;
  background-color: #fff;
  border: none;
  background-position: center;
  background-size: cover;
  transition: all 0.2s ease-in-out !important;
  border-radius: 5px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: none;
    opacity: 0.9;
    background-image: linear-gradient(
      43deg,
      #00c49a 0%,
      #00c49a 46%,
      #00c49a 100%
    );
  }

  &:focus {
    transform: translateY(-5px);
    box-shadow: none;
    opacity: 0.9;
    background-image: linear-gradient(
      43deg,
      #00c49a 0%,
      #00c49a 46%,
      #00c49a 100%
    );
  }
}

.card-1 {
  background-color: #4158d0;
  background-image: linear-gradient(#b2b2b2 100%, #b2b2b2 100%, #b2b2b2 100%);
}

.card-2 {
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #00c49a 0%,
    #00c49a 46%,
    #00c49a 100%
  );
}

//.dateRow{
//  margin-top: -20px;
//}

</style>
