var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "add-new-user-sidebar",
      visible: _vm.isAddNewShiftSidebarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-add-new-shift-sidebar-active", val)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
              _c(
                "div",
                {
                  staticClass:
                    "\n          d-flex\n          justify-content-between\n          align-items-center\n          content-sidebar-header\n          px-2\n          py-1\n        "
                },
                [
                  _c("h5", { staticClass: "mb-0" }, [
                    _vm._v(
                      " Vacant Shift - " +
                        _vm._s(
                          _vm.momentFormat(
                            _vm.selectedDate,
                            "dddd, DD MMMM YYYY"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("feather-icon", {
                    staticClass: "ml-1 cursor-pointer",
                    attrs: { icon: "XIcon", size: "16" },
                    on: { click: hide }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "p-2" },
                [
                  _c(
                    "validation-observer",
                    { ref: "createShiftForm" },
                    [
                      _c(
                        "b-form",
                        {
                          ref: "add_shift",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                            }
                          }
                        },
                        [
                          _vm.isHaveLeave
                            ? _c(
                                "b-alert",
                                { attrs: { show: "", variant: "danger" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "alert-body" },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-25",
                                        attrs: { icon: "AlertCircleIcon" }
                                      }),
                                      _c("span", { staticClass: "ml-25" }, [
                                        _vm._v(_vm._s(_vm.leaveErrorMessage))
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", [_vm._v("Professions")]),
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "Professions",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      options: _vm.roleList,
                                                      label: "text",
                                                      placeholder:
                                                        "Select Profession",
                                                      multiple: "",
                                                      reduce: function(
                                                        roleList
                                                      ) {
                                                        return roleList.id
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.selectedRole,
                                                      callback: function($$v) {
                                                        _vm.selectedRole = $$v
                                                      },
                                                      expression: "selectedRole"
                                                    }
                                                  }),
                                                  errors[0]
                                                    ? _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "The Role field is required"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", [_vm._v("Role (Optional)")]),
                                      _c("validation-provider", {
                                        attrs: { name: "Role", rules: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "v-select",
                                                    {
                                                      attrs: {
                                                        options: _vm.RoleLists,
                                                        reduce: function(
                                                          RoleLists
                                                        ) {
                                                          return RoleLists.value
                                                        },
                                                        label: "text",
                                                        placeholder:
                                                          "Select Role",
                                                        multiple: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectedRoles,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.selectedRoles = $$v
                                                        },
                                                        expression:
                                                          "selectedRoles"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "no-options"
                                                          },
                                                          slot: "no-options"
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "ripple",
                                                                  rawName:
                                                                    "v-ripple.400",
                                                                  value:
                                                                    "rgba(255, 255, 255, 0.15)",
                                                                  expression:
                                                                    "'rgba(255, 255, 255, 0.15)'",
                                                                  modifiers: {
                                                                    "400": true
                                                                  }
                                                                }
                                                              ],
                                                              attrs: {
                                                                size: "sm",
                                                                variant:
                                                                  "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$router.push(
                                                                    "/settings?active_tab=1"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [_vm._v("Add")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  ),
                                                  errors[0]
                                                    ? _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "The Professions field is required"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.ShiftTemplates.length !== 0
                            ? _c("h5", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Shift templates (select applicable shift) "
                                )
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "container-fluid" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "scrolling-wrapper row flex-row flex-nowrap pt-2"
                              },
                              _vm._l(_vm.ShiftTemplates, function(template) {
                                return _c(
                                  "div",
                                  {
                                    key: template.id,
                                    staticClass: "col-5",
                                    attrs: { index: template.id },
                                    on: {
                                      click: function($event) {
                                        return _vm.shiftTemplateSet(template)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "card card-block",
                                        class:
                                          template.isSelect === true
                                            ? _vm.cardBckground2
                                            : _vm.cardBckground1,
                                        on: {
                                          click: function($event) {
                                            return _vm.changeColor(template)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "padding-left": "7px",
                                                      color: "white",
                                                      "font-size": "x-small"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          template.start_time
                                                        ) +
                                                        " - " +
                                                        _vm._s(
                                                          template.end_time
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "background-color":
                                                        "white",
                                                      "text-overflow":
                                                        "ellipsis",
                                                      "margin-right": "7px"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          padding: "3px",
                                                          color: "#353434",
                                                          "font-size": "x-small"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            template.service.name.substring(
                                                              0,
                                                              12
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]),
                          _c(
                            "div",
                            {},
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c("label", [_vm._v("Start Time")]),
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Start Time",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c(
                                                        "b-form-group",
                                                        [
                                                          _c(
                                                            "b-input-group",
                                                            {
                                                              staticClass:
                                                                "input-group-merge"
                                                            },
                                                            [
                                                              _c(
                                                                "b-input-group-prepend",
                                                                {
                                                                  attrs: {
                                                                    "is-text":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "feather-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon:
                                                                          "ClockIcon"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  config: {
                                                                    enableTime: true,
                                                                    dateFormat:
                                                                      "H:i:ss",
                                                                    noCalendar: true
                                                                  },
                                                                  placeholder:
                                                                    "Start Time"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.selectedStartTime,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.selectedStartTime = $$v
                                                                  },
                                                                  expression:
                                                                    "selectedStartTime"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      errors[0]
                                                        ? _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "The Start Time field is required"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c("label", [_vm._v("End Time")]),
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "End Time",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c(
                                                        "b-form-group",
                                                        [
                                                          _c(
                                                            "b-input-group",
                                                            {
                                                              staticClass:
                                                                "input-group-merge"
                                                            },
                                                            [
                                                              _c(
                                                                "b-input-group-prepend",
                                                                {
                                                                  attrs: {
                                                                    "is-text":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "feather-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon:
                                                                          "ClockIcon"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  config: {
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat:
                                                                      "H:i:ss"
                                                                  },
                                                                  placeholder:
                                                                    "End Time"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.selectedEndTime,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.selectedEndTime = $$v
                                                                  },
                                                                  expression:
                                                                    "selectedEndTime"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      errors[0]
                                                        ? _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "The End Time field is required"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Break Minutes",
                                            "label-for": "login-email"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Break Minutes (Optional)",
                                              rules: "numeric"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "login-email",
                                                          name: "login-email",
                                                          type: "number"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .break_minutes,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "break_minutes",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.break_minutes"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "12" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Shift delivery (Optional)",
                                            "label-for": "lShift_delivery"
                                          }
                                        },
                                        [
                                          _vm.selectedServiceType
                                            ? _c("v-select", {
                                                attrs: {
                                                  options: _vm.shiftTypes,
                                                  label: "name",
                                                  placeholder: "Select delivery"
                                                },
                                                model: {
                                                  value: _vm.selectedShiftType,
                                                  callback: function($$v) {
                                                    _vm.selectedShiftType = $$v
                                                  },
                                                  expression:
                                                    "selectedShiftType"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-1" },
                            [
                              _vm.visible_locums == false
                                ? _c("h6", { staticClass: "mb-2" }, [
                                    _vm._v(" Hourly rate (Optional) ")
                                  ])
                                : _vm._e(),
                              _vm.visible_locums == false
                                ? _c(
                                    "b-row",
                                    { staticClass: "mb-1" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "3" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: { value: "standard" },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .salary_staff_rate_type,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "salary_staff_rate_type",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.salary_staff_rate_type"
                                              }
                                            },
                                            [_vm._v(" Standard ")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "3" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: { value: "enhanced" },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .salary_staff_rate_type,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "salary_staff_rate_type",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.salary_staff_rate_type"
                                              }
                                            },
                                            [_vm._v(" Enhanced ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.visible_locums == false &&
                              (_vm.form.salary_staff_rate_type === "standard" ||
                                _vm.form.salary_staff_rate_type === "enhanced")
                                ? _c("validation-provider", {
                                    attrs: {
                                      name: "Salaried hourly rate",
                                      rules: "required|numeric"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c(
                                                "b-input-group",
                                                {
                                                  attrs: {
                                                    prepend: "£",
                                                    size: "md"
                                                  }
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    staticClass: "float-right",
                                                    attrs: {
                                                      id: "stand",
                                                      placeholder:
                                                        "Enter hourly rate",
                                                      type: "number"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .salary_staff_rate,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "salary_staff_rate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.salary_staff_rate"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm.form
                                                .salary_staff_rate_type ===
                                                "standard" &&
                                              !_vm.standard_rate_p_h
                                                ? _c("small", [
                                                    _vm._v(
                                                      "Please add standard rate in setting or enter manually"
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm.form
                                                .salary_staff_rate_type ===
                                                "enhanced" &&
                                              !_vm.enhanced_rate_p_h
                                                ? _c("small", [
                                                    _vm._v(
                                                      "Please add enhanced rate in setting or enter manually"
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _c("br"),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                : _vm._e(),
                              _vm.visible_locums === true
                                ? _c(
                                    "div",
                                    [
                                      _c("h6", { staticClass: "mb-1" }, [
                                        _vm._v("Hourly rate (Optional)")
                                      ]),
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "Locum hourly rate",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "b-input-group",
                                                    {
                                                      attrs: {
                                                        prepend: "£",
                                                        size: "md"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        staticClass:
                                                          "float-right",
                                                        attrs: {
                                                          id: "rate",
                                                          name: "login-email",
                                                          placeholder:
                                                            "Enter hourly rate",
                                                          type: "number"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form.locum_rate,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "locum_rate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.locum_rate"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  !_vm.locum_rate_p_h
                                                    ? _c("small", [
                                                        _vm._v(
                                                          "Please add locum rate in setting or enter manually"
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  _c("br"),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("div", { staticClass: "mt-2" }, [
                            _c(
                              "h5",
                              {
                                staticClass: "mb-2 cursor-pointer",
                                on: {
                                  click: function($event) {
                                    return _vm.hideNoteAndShiftTypeFunction()
                                  }
                                }
                              },
                              [
                                !_vm.hideNoteAndShiftType
                                  ? _c("span", [_vm._v("+")])
                                  : _vm._e(),
                                _vm.hideNoteAndShiftType
                                  ? _c("span", [_vm._v("-")])
                                  : _vm._e(),
                                _vm._v(" More Shift Options ")
                              ]
                            ),
                            _vm.hideNoteAndShiftType
                              ? _c(
                                  "div",
                                  { staticClass: "mt-1" },
                                  [
                                    _vm.services.length > 0
                                      ? _c(
                                          "div",
                                          { staticClass: "mt-1" },
                                          [
                                            _c(
                                              "b-row",
                                              { staticClass: "mt-2" },
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { md: "12" } },
                                                  [
                                                    _c(
                                                      "h5",
                                                      {
                                                        staticClass:
                                                          "mb-2 cursor-pointer",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.serviceTypeHideFunction()
                                                            _vm.selectedServiceType =
                                                              "clinical"
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Activity (Optional) "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        staticClass:
                                                          "float-left",
                                                        attrs: {
                                                          value: "clinical"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedServiceType,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.selectedServiceType = $$v
                                                          },
                                                          expression:
                                                            "selectedServiceType"
                                                        }
                                                      },
                                                      [_vm._v(" Clinical ")]
                                                    ),
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        staticClass:
                                                          "float-right",
                                                        attrs: {
                                                          value: "non-clinical"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedServiceType,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.selectedServiceType = $$v
                                                          },
                                                          expression:
                                                            "selectedServiceType"
                                                        }
                                                      },
                                                      [_vm._v(" Non-clinical ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              {},
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { md: "12" } },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("label"),
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              rules: _vm.selectedServiceType
                                                                ? "required"
                                                                : "",
                                                              name: "Service"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _vm.selectedServiceType ===
                                                                      "clinical"
                                                                        ? _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                options: _vm.services.filter(
                                                                                  function(
                                                                                    x
                                                                                  ) {
                                                                                    return (
                                                                                      x.type ===
                                                                                      "clinical"
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                label:
                                                                                  "name",
                                                                                placeholder:
                                                                                  "Select Service"
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.selectedService,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.selectedService = $$v
                                                                                },
                                                                                expression:
                                                                                  "selectedService"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  attrs: {
                                                                                    slot:
                                                                                      "no-options"
                                                                                  },
                                                                                  slot:
                                                                                    "no-options"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Please add services first "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm.selectedServiceType ===
                                                                      "non-clinical"
                                                                        ? _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                options: _vm.services.filter(
                                                                                  function(
                                                                                    x
                                                                                  ) {
                                                                                    return (
                                                                                      x.type ===
                                                                                      "non-clinical"
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                label:
                                                                                  "name",
                                                                                placeholder:
                                                                                  "Select Service"
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.selectedService,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.selectedService = $$v
                                                                                },
                                                                                expression:
                                                                                  "selectedService"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  attrs: {
                                                                                    slot:
                                                                                      "no-options"
                                                                                  },
                                                                                  slot:
                                                                                    "no-options"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Please add services first "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm.selectedServiceType
                                                                        ? _c(
                                                                            "small",
                                                                            {
                                                                              staticClass:
                                                                                "text-danger"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  errors[0]
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.selectedServiceType === "clinical" &&
                                    _vm.services.length !== 0
                                      ? _c("b-row", {})
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "mt-1" },
                                      [
                                        _c("label", [
                                          _vm._v("Notes (Optional)")
                                        ]),
                                        _c("b-form-textarea", {
                                          attrs: {
                                            id: "textarea-default",
                                            placeholder: "Note",
                                            rows: "3"
                                          },
                                          model: {
                                            value: _vm.form.note,
                                            callback: function($$v) {
                                              _vm.$set(_vm.form, "note", $$v)
                                            },
                                            expression: "form.note"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "mt-1",
                                        attrs: { value: true },
                                        model: {
                                          value: _vm.form.is_covid_shift,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "is_covid_shift",
                                              $$v
                                            )
                                          },
                                          expression: "form.is_covid_shift"
                                        }
                                      },
                                      [_vm._v(" COVID-19 Shift ")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mt-1" },
                                      [
                                        _c(
                                          "h5",
                                          { staticClass: "mb-2 text-bold" },
                                          [_vm._v("Shift is visible to")]
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: { cols: "12", md: "4" }
                                              },
                                              [
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    attrs: { value: false },
                                                    model: {
                                                      value: _vm.visible_locums,
                                                      callback: function($$v) {
                                                        _vm.visible_locums = $$v
                                                      },
                                                      expression:
                                                        "visible_locums"
                                                    }
                                                  },
                                                  [_vm._v(" Salaried Staff ")]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: { cols: "12", md: "8" }
                                              },
                                              [
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    attrs: { value: true },
                                                    model: {
                                                      value: _vm.visible_locums,
                                                      callback: function($$v) {
                                                        _vm.visible_locums = $$v
                                                      },
                                                      expression:
                                                        "visible_locums"
                                                    }
                                                  },
                                                  [_vm._v(" Locums ")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]),
                          _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-alert",
                                { attrs: { show: "", variant: "primary" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "alert-body" },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "text-bold text-black-50",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _c("h5", [
                                                _vm._v(" Total Hours: "),
                                                _vm.selectedEndTime &&
                                                _vm.selectedStartTime
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.totalHours
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  : _c("span", [_vm._v("0")])
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "text-bold text-black-50",
                                              attrs: { cols: "8" }
                                            },
                                            [
                                              _c("h5", [
                                                _vm._v(" Total Shift Cost: "),
                                                _c("span", [
                                                  _c("span", [
                                                    _vm._v(
                                                      "£ " +
                                                        _vm._s(
                                                          _vm.salariedStaffCost <=
                                                            0
                                                            ? 0
                                                            : _vm.salariedStaffCost
                                                        )
                                                    )
                                                  ]),
                                                  _vm.visible_locums === true
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "/ £" +
                                                            _vm._s(
                                                              _vm.locumCost <= 0
                                                                ? 0
                                                                : _vm.locumCost
                                                            )
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ])
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "flex mt-1" },
                            [
                              _vm.isHaveLeave
                                ? _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple.400",
                                          value: "rgba(255, 255, 255, 0.15)",
                                          expression:
                                            "'rgba(255, 255, 255, 0.15)'",
                                          modifiers: { "400": true }
                                        }
                                      ],
                                      attrs: {
                                        disabled: "",
                                        type: "submit",
                                        variant: "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.createShift()
                                        }
                                      }
                                    },
                                    [_vm._v(" Save ")]
                                  )
                                : _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple.400",
                                          value: "rgba(255, 255, 255, 0.15)",
                                          expression:
                                            "'rgba(255, 255, 255, 0.15)'",
                                          modifiers: { "400": true }
                                        }
                                      ],
                                      attrs: {
                                        type: "submit",
                                        variant: "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.createShift()
                                        }
                                      }
                                    },
                                    [_vm._v(" Save ")]
                                  ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(255, 255, 255, 0.15)",
                                      expression: "'rgba(255, 255, 255, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "ml-1",
                                  staticStyle: {
                                    background: "#ffffff",
                                    "box-shadow":
                                      "0px 0px 10px rgba(151, 151, 151, 0.2)",
                                    "border-radius": "4px"
                                  },
                                  attrs: { variant: "white" },
                                  on: { click: hide }
                                },
                                [_vm._v(" Cancel ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }